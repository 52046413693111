<div class="container">

    <section class="main-section">
      <div class="content">
        <div>
          <h1>What is Heim?</h1>
          <p>Heim is a revolutionary inter-blockchain infrastructure and a genesis engine for building and operating seamlessly interacting and interoperable holographic time-like blockchains secured by laws of quantum physics.</p>
          <p>A holographic temporal blockchain is an infinitely scalable permissionless distributed ledger employing quantum fully homomorphic encryption with verification(vQFHE) in a quantum setting that involves encoding the blockchain into time-like quantum states of photons and magnons that do not simultaneously coexist.  Hence, the block generation happens, "either before or after the present Now Block".</p>
          <p>The data on a holographic time-like blockchain is fully functional and secured by quantum homomorphic encryption based on lattice-based cryptosystem which allows us to compute on ciphertexts as if we would do them on plaintexts with the decrypted result matching the plaintext operations. Homomorphic refers to homomorphism in algebra with the encryption and decryption functions thought of as homomorphisms between plaintext and ciphertext spaces.</p>
          <p>Heim will double up as a quantum secured time-like decentralized internet of blockchains with an integrated interplanetary file system and persistent shared storage space.  Heim quantum-secured Internet will have its native physical miner-routers to be hosted on space-based x-ray laser-mounted WaferSat constellations comprising of thousands of Wafersats beaming down 100gbps planetary quantum internet broadband via free-space laser transmission to ground-based and wearable transceivers in all weather conditions with a wide coverage of all points of 3D earth’s surface including polar regions as well as beaming up to all orbital space stations/habitats in LEO, GEO, GSO, Polar, SSO, and HEO orbits. Shortly, Heim will aim to evolve itself into an interplanetary quantum internet replicating its orbital space-based router infrastructure of Earth on Lunar, Martian, and Venusian and Titanic orbits by 2030s.</p>
          <p>The hardware configuration of miner-routers will be based on hybrid magnonic-photonic-electronic architecture incorporating state-of-the-art GPUs and  Godel HPUs for enabling efficient blockchain search engines and quantum-classical queries.The protocol token of Heim AI is HEIM which will be used to reward miner-router operators in space and on Earth dynamically as well as to pay for quantum internet broadband service usage. There will not be any transaction or gas fees. Only hourly, daily, monthly, and yearly broadband utility fees will be applicable.</p>
        </div>
      </div>
    </section>
  
    <section class="box-section what-section" id="block1">
      <div class="heading_block">
        <h1>What is it for?</h1>
      </div>
  
      <div class="what-section-main-points">
        <div class="what-section-main-point what-section-main-point-right">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/quantum_computer.png" alt="Heim Quantum Computer">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>A self-organizing autonomous distributed operating system for DAOs, Autonomous Drones, Cars, and Vehicles.</p>
          </div>
        </div>
  
        <div class="what-section-main-point what-section-main-point-right">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/automated_software_techno.png" alt="Heim Automated Software">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>Automated metaprogramming and distribution of non-clonable modular plug-n-play distributed software endowed with global statefulness without involving any human agents.</p>
          </div>
        </div>
  
        <div class="what-section-main-point">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/photo6134262774403017371.jpg" alt="Godel Hyperchip">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>Godel hyperchip of the Heim ecosystem enables instantaneous quantum energy transportation from space-based solar energy farms directly to end-user devices.</p>
          </div>
        </div>
      </div>
  
      <div class="bullet_points">
        <p>Financial Operations with an infinitely divisible currency and monetary unit.</p>
        <p>Sensor-Actor networks and the Internet of Things.</p>
        <p>Computational and Execution Engine for digital assets and digital avatars of cyber-physical systems, sensor-actor networks, drones, self-driven cars, and robots.</p>
        <p>Provably fair lotteries, betting, gaming, and traditionally unfathomable supraturing beyond Von-Neumann hypercomputing tasks.</p>
        <p>DeFi Yield mining and AI mining.</p>
        <p>Godel hyperchip of the Heim ecosystem enables instantaneous quantum energy transportation from space-based solar energy farms directly to end-user devices, EV cars, autonomous EVs, and drones on the fly without requiring any charging points and charging station infrastructure.</p>
        <p>Seamless non-custodial value transfers from legacy blockchains to Heim and vice versa via zkSNARKed privacy-preserving drivechains without requiring any centralized (CEXes) and decentralized (DEXes) exchanges.</p>
        <p>Heim and Godel engine enable searchable tracking of Digital Avatars of all sensors, robots, physical/digital objects, and even cybernetic networked humans(via Brain-Machine Interface), e.g., Cyborgs with real-time one-to-one control flows.</p>
        <p>Irreversible offline transactions without internet connection and realization of physical cryptocurrency bills/notes.</p>
      </div>
    </section>
  
    <section class="box-section why-section" id="block2">
      <div class="heading_block">
        <h1>Why Heim?</h1>
      </div>
  
      <div class="what-section-main-points">
        <div class="what-section-main-point what-section-main-point-right">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/provably_fair.png" alt="Heim Provable Diagram">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>Truly decentralized gaming such as lotteries and betting apps require quantum sources of randomness to make them provably fair.</p>
          </div>
        </div>
  
        <div class="what-section-main-point what-section-main-point-right">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/blockchain_search_engine.png" alt="Heim blockchain Search Engine">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>
              A real-time blockchain search engine, distributed AI engine, IoT track, and execute engine are required for the continuous growth of the decentralized economy and IoT infrastructure.
            </p>
          </div>
        </div>
  
        <div class="what-section-main-point">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/turing_complete.png" alt="Heim is Turing Complete">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>Turing-completeness of smart contracts makes it far too easier for malicious criminal-minded hackers to find bugs, exploits, and 0-days and steal tens of millions of dollars often in DeFi space.</p>
          </div>
        </div>
      </div>
  
      <div class="bullet_two">
        <p>
          With emerging quantum computing machines such as Google’s and that of China’s Photonic quantum chip more than a billion times more powerful than Google’s will make cryptographic security of all public blockchains including Bitcoin and Ethereum vulnerable to brute force and key collision attacks thereby putting hundreds of billions of dollar(soon trillions of dollar) of public value at extreme risk of sabotage.
        </p>
  
        <p>
          Truly autonomous and intelligent drones and self-driven cars are not technologically feasible without having the background consciousness and associated self-awareness of human-level or suprahuman-level due to the uncertain nature of the real-physical world. All algorithmic systems will fail even the so-called reinforcement learner algorithms eventually.
        </p>
  
        <p>
          Decentralized organizations comprising of human miners and token holders running public blockchains are susceptible to the darkest tendencies of human nature, e.g., jealousy, anger, greed, malice, conscious/unconscious bias, and so on. Hence they can’t govern decentralized autonomous organizations in a way that would be fair to all parties and stakeholders including token-holders/holders and miners at all times.
        </p>
  
        <p>
          Decentralized Finance-DeFi or Open Finance can’t grow much without a robust privacy solution. Native Heim token of Heim ensures the anonymity of the sender and receiver as well as protects the privacy of both account balances and code. Any tokens/contracts/protocols/dApps built on Heim will have the same anonymous privacy-preserving property by default.
        </p>
  
        <p>
          Turing-completeness of smart contracts makes it far too easier for malicious criminal-minded hackers to find bugs, exploits, and 0-days and steal tens of millions of dollars often in DeFi space.
        </p>
  
        <p>
          Swapping a pair of cryptos or transferring crypto values one way during face-to-face meetings between reasonably trusted parties should not require an Internet connection. Simple P2P networks like Bluetooth connections should be able to validate such transactions irreversibly. It can be done by engineering some non-clonable primitives both logically and electronically or phonically or physically. This will give birth to a new type of physical crypto notes/bills, that does not require an internet connection for validation.
        </p>
  
        <p>
          Feeless essential services like processing of real-time queries and searches will drive up adoption of the Heim technology ecosystem and price appreciation of Heim tokens.
        </p>
      </div>
    </section>
  
    <section class="box-section godel-sec">
      <img src="../assets/images/photo6082165559836912550.jpg" alt="Current Technologies vs Heim Technology">
      <br>
      <img src="../assets/images/photo6156619591007185903.jpg" alt="Godel Hyperchip">
  
      <p>
        Mine AppCoins of Heim Ecosystem Primes (Symbol: ZMP) with Godel Hyperchip and simultaneously help us secure the Heim network.
      </p>
  
      <h1>Godel Hyperchip</h1>
      <p>Worlds first USB4 compatible multipurpose hypercomputing chip compatible with Windows, Linux(Ubuntu), macOS, RIOT, HarmonyOS and PureOS.</p>
      <div class="btn-container">
        <a download class="diff-btn" href="../assets/downloads/HeimMHPU.pdf">Godel HPU Datasheet</a>
      </div>
      
      <div class="btn-container">
        <a href="#" class="diff-btn">Learn more</a>
        <a href="#contact">Preorder now</a>
      </div>
    </section>
  
    <section class="box-section team_section" id="block3">
      <div class="heading_block">
        <h1>Our Team</h1>
      </div>
  
      <div class="team-main">
        <!-- <div class="team_head">
          <div class="level-one">
            <h2>Howard Wu</h2>
            <img src="../assets/images/photo6075421224231611405.jpg" alt="">
          </div>
  
          <div class="level-two">
            <div class="left-level">
              <h2>Executive Adviser at Huawei Technologies.</h2>
              <p>Bellevue, Washington, United States.</p>
            </div>
            <a href="#">LinkedIn</a>
          </div>
        </div> -->
        <div class="team-members">
          <div class='wrapper'>
            <div class='carousel'>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/ace-of-diamonds.svg" alt="Ace of Diamonds">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>SpaceEagle</p>
                </div>
              </div>
  
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/ace-of-spades.svg" alt="Ace Of Spades">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Subbies</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/ace-of-hearts.svg" alt="Ace of Hearts">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Rehn</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/playing-card.svg" alt="Playing Card">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Danny</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/king-of-diamonds-card.svg" alt="King of Diamonds">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Eightdim</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/icons8-joker-100.png" alt="Joker">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Kinster</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/icons8-queen-of-spades-100.png" alt="Queen of Spades">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Twitchell</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/icons8-jack-of-clubs-100.png" alt="Jack of Clubs">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Rector</p>
                </div>
              </div>
              <div class='carousel__item'>
                <div class='carousel__item-head'>
                  <img src="../assets/images/team/icons8-king-of-clubs-100.png" alt="King of Clubs">
                </div>
                <div class='carousel__item-body'>
                  <p class='title'>Yoshioka</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="box-section rm-section" id="roadmap">
      <div class="heading_block">
        <h1>Roadmap</h1>
      </div>
      <div class="roadmap-block">
  
        <div class="rblock-inside">
          <h2>Q1 - 2021</h2>
          <p>
            Development & testing of Godel Hyperchip technologies. Hiring full-time Chief Software and Hardware project managers. Godel Alpha Digital Asset & Avatar search engine Release.
          </p>
        </div>
  
        <div class="rblock-inside">
          <h2>Q2 - 2021</h2>
          <p>
            Selecting & rewarding grants to independent R&D labs/companies to commercialize productions and distribution of open-source Godel Hyperchip and Godel-powered devices, including wearable mind-machine interfaces(BMIs).
          </p>
        </div>
  
        <div class="rblock-inside">
          <h2>Q3 - 2021</h2>
          <p>
            Alpha Heim Testnet released Heim Foundation incorporated in Liechtenstein, announcing strategic partnerships and engagements.
          </p>
        </div>
  
        <div class="rblock-inside">
          <h2>Q4 - 2021</h2>
          <p>Beta testnet of Heim Release First batch of Godel hyperchips release.</p>
        </div>
  
        <div class="rblock-inside">
          <h2>Q1 - 2022</h2>
          <p>
            Beta testnet of Godel Engine Release Rewarding farther grants to R&D labs and qualified Heim ecosystem developers.
          </p>
        </div>
  
  
        <div class="rblock-inside">
          <h2>Q2 - 2022</h2>
          <p>Heim Core 1.0 Release Drivechains interface for legacy blockchains.</p>
        </div>
  
        <div class="rblock-inside">
          <h2>Q3 - 2022</h2>
          <p>Fully functional Godel engine released Decentralized distribution of the second batch of Godel hyperchips.</p>
        </div>
  
        <div class="rblock-inside">
          <h2>Q4 - 2022</h2>
          <p>Debugged Heim Core 1.1 Release. Standalone Heim Mainnet Wallet Release.</p>
        </div>
      </div>
  
    </section>
    <section class="box-section investor-deck" id="investor-deck">
      <div class="heading_block">
        <h1>Investors</h1>
      </div>
      <ul>
        <li>
          <a href="../assets/downloads/HeimTokenEconomics.pdf" download="HeimTokenEconomics.pdf">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span class="bg">Token Economics</span>
          </a>
        </li>
        <li>
          <a href="../assets/downloads/HeimTechnicalWhitepaper.pdf" download="HeimTechnicalWhitepaper.pdf">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span class="bg">Heim Technical Whitepaper</span>
          </a>
        </li>
        <li>
          <a href="../assets/downloads/Team-BusinessModel-TAM.pdf" download="Team-BusinessModel-TAM.pdf">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span class="bg">Team-Business Model-TAM</span>
          </a>
        </li>
        <li>
          <a href="../assets/downloads/HeimMHPU.pdf" download="Godel-HPU-Datasheet">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span class="bg">Godel HPU Datasheet</span>
          </a>
        </li>
      </ul>
    </section>
    <section class="box-section investor-deck special" id="special">
      <!-- <div class="heading_block">
        <h1>Investor Deck</h1>
      </div> -->
      <div class="special-block">
        <a class="spec" href="../../assets/downloads/Blockchain_CD.pdf" download="High Yield Blockchain CD"><img src="../../assets/downloads/photo6334317025291251679.jpg" alt=""></a>
        <a href="../../assets/downloads/Blockchain_CD.pdf" download="High Yield Blockchain CD">High Yield Blockchain CD</a>
        <br>
        <a href="https://medium.com/@zumbaxaya/soulbound-interests-of-heim-tokens-94337fea74b8" target="_blank">Soulbound Interests of HEIM</a>
      </div>
    </section>

  
    <section class="box-section developer-sec" id="block5">
      <div class="heading_block">
        <h1>Developers</h1>
      </div>
      <div class="deve-box">
        <div class="left-box">
          <div class="dev-box">
            <div class="img-box">
              <img src="../assets/images/developers/img_one.png" alt="For Heim Developers 1">
            </div>
            <div class="text-box">
              <p>
                Build cross-platform privacy-preserving scalable decentralized autonomous DAOs and applications as well as parachains of legacy blockchain protocols at lightning speed.
              </p>
            </div>
          </div>
  
          <div class="dev-box">
            <div class="img-box">
              <img src="../assets/images/developers/img_two.png" alt="For Heim Developers 2">
            </div>
            <div class="text-box">
              <p>
                Leverage Godel Hyperchip to propel your embedded IoT applications to Andromeda at tachyonic FTL speed.
              </p>
            </div>
          </div>
  
          <div class="dev-box">
            <div class="img-box">
              <img src="../assets/images/developers/img_three.png" alt="For Heim Developers 3">
            </div>
            <div class="text-box">
              <p>
                Regain complete control over your software and content by hosting your Dapps on a censorship-proof DappStore targeting end-users with blockchain-powered Linux and HarmonyOS-based smartphones and smartwatches.
              </p>
            </div>
          </div>
  
          <div class="dev-box">
            <div class="img-box">
              <img src="../assets/images/developers/img_four.png" alt="For Heim Developers 4">
            </div>
            <div class="text-box">
              <p>
                Access the God-like power of Godel hyperchip and blockchain analyzer engine to launch digital avatars and tokens functionally representing real-world objects, devices, agents, aggregates, and people.
              </p>
            </div>
          </div>
  
          <div class="dev-box">
            <div class="img-box">
              <img src="../assets/images/developers/img_five.png" alt="For Heim Developers 5">
            </div>
            <div class="text-box">
              <p>
                Deploy censorship-proof secure encrypted messenger and social media apps that don’t require an army of programmers and coders for building and maintenance.
              </p>
            </div>
          </div>
  
          <div class="dev-box">
            <div class="img-box">
              <img src="../assets/images/developers/img_six.png" alt="For Heim Developers 6">
            </div>
            <div class="text-box">
              <p>
                Get total protection from attack vectors of external data oracles with built-in miner-less data integrity provided by on-chain automorphic decision agents internally.
              </p>
            </div>
          </div>
  
          <div class="dev-box">
            <div class="img-box dark-img-box-background">
              <img src="../assets/images/developers/img_seven.png" alt="For Heim Developers 7">
            </div>
            <div class="text-box">
              <p>
                Assert your self-sovereignty while destroying the legacy finance and big-tech monopolies by root.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
    <section class="box-section killer-apps">
      <div class="heading_block">
        <h1>Decentralized Applications powered by Heim.</h1>
      </div>
  
      <div class="what-section-main-points">
        <div class="what-section-main-point what-section-main-point-right">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/offline_crypto.png" alt="Heim Offline Crypto">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>Irreversible offline transactions without internet connection and realization of physical cryptocurrency bills.</p>
          </div>
        </div>
  
        <div class="what-section-main-point what-section-main-point-right">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/decentralised_payment_gateway.png" alt="Heim Decentralised Payment Gateway">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>KYC-free Universal Decentralized Payment Processor for more than 100 cryptocurrencies and stablecoins besides Heim enabled via recursive zkSNARKed drivechains.</p>
          </div>
        </div>
  
        <div class="what-section-main-point">
          <div class="what-section-main-point-img-wrapper">
            <img src="../assets/images/wireless.png" alt="Heim Wireless">
          </div>
  
          <div class="what-section-main-point-text-wrapper">
            <p>Secure wireless energy distribution and trading via instantaneous quantum energy teleportation from space-based and terrestrial solar energy farms and small nuclear fusion reactors.</p>
          </div>
        </div>
      </div>
  
      <div class="killer-points" style="margin-top: 50px;">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>Hack-proof distributed autonomous operating systems for DAOs, autonomous cars, and drones.</p>
      </div>
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Searchable collaborative Digital Avatars of real-world devices, autonomous cars, sensors, bots, drones, and individuals. The solution is implemented with globally unique zero-knowledge identifiers extending the working time and capacities of individuals and organizations.
        </p>
      </div>
  
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Human Readable Anonymous agreements and guarantees known as zkSAGs to replace smart contracts.
        </p>
      </div>
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Seamless, non-custodial value transfers from legacy blockchains to Heim and vice versa, by implementing recursive zkSNARKed privacy-preserving drivechains without requiring any centralized (CEXes)and decentralized (DEXes) exchanges or 3rd party wallets.
        </p>
      </div>
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Decentralized censorship-proof secure encrypted messenger and social media apps: that don’t require an army of programmers and coders to build and maintain.
        </p>
      </div>
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Godel hyperchip of Heim ecosystem will enable secure wireless energy distribution and trading via instantaneous quantum energy teleportation from space-based and terrestrial solar energy farms and small nuclear fusion reactors directly to end-user devices, EVs, drones, satellites, and space stations as well as space colonies soon.
        </p>
      </div>
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Censorship-proof Botverse DappStore allowing developers to list their Dapps for easy end-user access and marketing based on key phrases and keywords.
        </p>
      </div>
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>Censorship-proof secure cloud hosting protected by functional encryption of Heim technology.</p>
      </div>
  
      <div class="killer-points">
        <svg class="pulse" x="0px" y="0px" width="70px" height="70px" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="6" />
          <circle class="pulse-circle" cx="50" cy="50" r="4" stroke-width="2" />
          <circle class="pulse-circle-2" cx="50" cy="50" r="4" stroke-width="2" />
        </svg>
        <p>
          Realtime financial intelligence mapped on a 3D avatar of rotating planet Earth.
        </p>
      </div>
    </section>
  
    <div>
      <video style="width:100%;max-height:100%" id="videoId1" autoplay muted loop preload="none">
        <source src="../assets/images/earth_f.mp4" type="video/mp4">Your browser does not support the video tag.
      </video>
    </div>
  

  
    <section class="footer-sec" id="contact">
      <div class="links-sec">
        <a href="mailto:info@heim.ai">Email <span>info[at]heim.ai</span></a>
        <a href="https://t.me/heimtechnology" target="_blank">Telegram <span>https://t.me/heimtechnology</span></a>
        <!-- <a href="">Brax.Me <span>#heimtech</span></a> -->
        <a href="https://discord.gg/Z4vQrvnHv2" target="_blank">Discord<span>https://discord.gg/Z4vQrvnHv2</span></a>
        <a href="https://github.com/Heim-AI" target="_blank">Github<span>https://github.com/Heim-AI</span></a>
      </div>
    </section>
  </div>
