<div class="nav-container">
  <div class="left_container">
    <a href="/" class="mob-link">Home</a>
    <a href="#block1" class="mob-link">What is it for ?</a>
    <a href="#block2" class="mob-link">Why Heim ?</a>
    <a href="#block3" class="mob-link">Team</a>
    <a href="#roadmap" class="mob-link">Roadmap</a>
    <a href="#investor-deck" class="mob-link">Investors</a>
    <a href="#block5" class="mob-link">Developers</a>
        </a>
    <a href="#contact" class="mob-link">Contact</a>
  </div>
</div>

<section class="box-section1">
  <header>
    <div class="logo-box">
      <img src="assets/images/logo/logo-with-quote.jpeg" alt="Heim Logo">
    </div>
   
    <div class="closeBtn" id="menu-btn">
      <svg class="ham hamRotate ham1" viewBox="0 0 100 100" width="80" onclick="this.classList.toggle('active')"
        >
        <path class="line top"
          d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
        <path class="line middle" d="m 30,50 h 40" />
        <path class="line bottom"
          d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
      </svg>
    </div>
    <nav style="position: relative;">
      <a href="/" class="nav-itemm">Home</a>
      <a href="#block1" class="nav-itemm">What is it for ?</a>
      <a href="#block2" class="nav-itemm">Why Heim ?</a>
      <a href="#block3" class="nav-itemm">Team</a>
      <a href="#roadmap" class="nav-itemm">Roadmap</a>
      <a href="#investor-deck" class="nav-itemm">Investors</a>
      <a href="#block5" class="nav-itemm">Developers</a>
           </a>
      <a href="#contact" class="nav-itemm">Contact</a>
     
      <span class="nav-indicator"></span>
    </nav>
  </header>
</section>


<router-outlet></router-outlet>

