import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  linkText:string = "What's Botchain ?";
  shownav:boolean = false;
  showModal:boolean = false;

  showNav(){
    this.shownav = !this.shownav;
  }
  changeText(e:any){
    this.linkText=e.innerHTML;
  }

  openModal(){
    this.showModal = !this.showModal;
  }

  closeNav(){
    this.shownav = false;
  }
}
